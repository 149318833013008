import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AlternovaLogo,
  Box,
  BoxBottomGraphic,
  BoxContainer,
  BoxLabel,
  BoxSubtext,
  BoxTopGraphic,
  Caption,
  Container,
  EthBalance,
  MintCount,
  MintCountContainer,
  MintCountNum,
  MintCountWord,
  Pokeball,
  PokeballContainer,
  PokeballText,
  RemainingCount,
  RemainingCountContainer,
  RemainingCountText,
  TotalContainer,
  TotalText,
  VideoBG,
} from './Mint.styles';
import Frame from 'components/Frame';
import { isEmpty } from 'lodash';

import poster from 'assets/still_arena.webp';

import alternovaTwo from 'assets/alternova-two.svg';
import logo from 'assets/alternova.svg';
import { Button, Toast } from 'base-components';
// import {
//   NATIVE_TOKEN_ADDRESS,
//   useAddress,
//   useBalance,
//   useContract,
//   useContractRead,
//   useNetworkMismatch,
//   useSDKChainId,
//   useSwitchChain,
// } from '@thirdweb-dev/react';

import { BigNumber, ethers } from 'ethers';

import {
  MINT_CONTRACT_ADDRESS,
  STRK_CONTRACT_ADDRESS,
} from 'constants/addresses';

import corner from 'assets/corner.svg';
import boxTop from 'assets/box-top.svg';
import boxBottom from 'assets/box-bottom.svg';
import incubatorWhite from 'assets/incubator-white.svg';
import incubatorColor from 'assets/incubator-color.svg';

import {
  CornerBottomLeft,
  CornerBottomRight,
  CornerTopLeft,
  CornerTopRight,
} from 'base-components/Modal/Modal.styles';
import ConnectButton from 'components/ConnectButton';
import MintModal from './MintModal';
import { getFormattedNumber } from 'utils/format';
import { LAUNCH_TIME } from 'constants/time';
import {
  useAccount,
  useBalance,
  useContract,
  useContractRead,
  useNetwork,
  useProvider,
} from '@starknet-react/core';
import { MINT_ABI, STRK_ABI } from 'abi/abi';
import { cairo, TransactionFinalityStatus } from 'starknet';
import { toBN } from 'utils/conversions';
import { ResponsiveStore } from 'stores';

const FREE_MINT_LIMIT = 0;

function Mint({ title }) {
  const [selectedIncubators, setSelectedIncubators] = useState(
    new Set<number>()
  );

  const count = useMemo(() => selectedIncubators.size, [selectedIncubators]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMobile } = ResponsiveStore;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onItemClick = useCallback(
    (item: number) => {
      const newSet = new Set(Array.from(selectedIncubators));
      if (newSet.has(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }

      setSelectedIncubators(newSet);
    },
    [selectedIncubators]
  );

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setLoading(false);
    setSuccess(false);
    setSelectedIncubators(new Set([]));
  }, []);

  const { address: walletAddress, account } = useAccount();

  const { provider } = useProvider();

  const { contract: mintContract } = useContract({
    address: MINT_CONTRACT_ADDRESS,
    abi: MINT_ABI,
  });

  const { contract: strkContract } = useContract({
    address: STRK_CONTRACT_ADDRESS,
    abi: STRK_ABI,
  });

  const { data: anRemainingToAssign } = useContractRead({
    functionName: 'remainingToAssign',
    args: [],
    abi: MINT_ABI,
    address: MINT_CONTRACT_ADDRESS,
    watch: true,
  });

  const { data: totalSupply } = useContractRead({
    functionName: 'totalSupply',
    args: [],
    abi: MINT_ABI,
    address: MINT_CONTRACT_ADDRESS,
    watch: true,
  });

  const { data: maxSupply } = useContractRead({
    functionName: 'maxSupply',
    args: [],
    abi: MINT_ABI,
    address: MINT_CONTRACT_ADDRESS,
    watch: true,
  });

  const { data: mintPrice } = useContractRead({
    functionName: 'getMintPrice',
    args: [1],
    abi: MINT_ABI,
    address: MINT_CONTRACT_ADDRESS,
    watch: true,
  });

  const { data: freeMintDone } = useContractRead({
    functionName: 'freeMintDone',
    args: [walletAddress!],
    abi: MINT_ABI,
    address: MINT_CONTRACT_ADDRESS,
    watch: true,
  });

  const { data: allowance } = useContractRead({
    functionName: 'allowance',
    args: [walletAddress!, MINT_CONTRACT_ADDRESS],
    abi: STRK_ABI,
    address: STRK_CONTRACT_ADDRESS,
    watch: true,
  });

  // const { data: saleStartTimestamp } = useContractRead({
  //   functionName: 'saleStartTimestamp',
  //   args: [],
  //   abi: MINT_ABI,
  //   address: MINT_CONTRACT_ADDRESS,
  //   watch: true,
  // });
  // const { data: ownerOf } = useContractRead({
  //   functionName: 'owner_of',
  //   args: [cairo.uint256(6865)],
  //   abi: ABI,
  //   address: CONTRACT_ADDRESS,
  //   watch: true,
  //   parseResult: true,
  //   parseArgs: true,
  // });

  const {
    isLoading: isLoadingSTRK,
    isError,
    error,
    data: dataSTRK,
  } = useBalance({
    address: walletAddress,
    watch: true,
    token: '0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d',
  });

  const strkBalance = useMemo(() => {
    if (isLoadingSTRK || !dataSTRK) {
      return 0;
    }

    return Number(dataSTRK?.formatted);
  }, [dataSTRK, isLoadingSTRK]);

  const amountToMint = useMemo(() => {
    // return 0.0001;
    // HOUSE_MONEY * MAX_BET_PERCENT / MAX_BET_PERCENT_DIVISOR
    if (!mintPrice) {
      return 0;
    }

    // @ts-ignore
    return count * Number(ethers.utils.formatEther(mintPrice));
  }, [mintPrice, count]);

  const isMintFree = useMemo(() => {
    if (Number(totalSupply) < FREE_MINT_LIMIT) {
      return true;
    }

    return false;
  }, [totalSupply]);

  const onMint = useCallback(async () => {
    openModal();
    setLoading(true);

    try {
      strkContract?.connect(account!);

      let mintRes;
      let successText;

      if (isMintFree) {
        mintRes = await mintContract?.freeMintAn();
        successText = 'You have successfully minted ALTER NOVA!';
      } else {
        if (Number(allowance) < amountToMint * Math.pow(10, 18)) {
          const approveCall = strkContract?.populate('approve', [
            cairo.felt(MINT_CONTRACT_ADDRESS),
            cairo.uint256(amountToMint * Math.pow(20, 18)),
          ]);
          const approveRes = await strkContract?.approve(approveCall?.calldata);
          await provider.waitForTransaction(approveRes.transaction_hash, {
            successStates: [TransactionFinalityStatus.ACCEPTED_ON_L2],
          });
        }

        mintContract?.connect(account!);
        const mintCall = mintContract?.populate('mintAN', [
          cairo.uint256(count),
        ]);
        mintRes = await mintContract?.mintAN(mintCall?.calldata);

        successText = `You have successfully minted ALTER NOVA x${count}!`;
      }

      await provider.waitForTransaction(mintRes.transaction_hash);

      setLoading(false);
      setSuccess(true);

      Toast.success(successText);
    } catch (e: any) {
      closeModal();
      setLoading(false);
      Toast.error(e?.message || 'Something went wrong');

      console.log('Error:', e);
    }
  }, [
    count,
    mintContract,
    strkContract,
    amountToMint,
    openModal,
    account,
    provider,
    closeModal,
    isMintFree,
    allowance,
  ]);

  const isButtonDisabled = useMemo(() => {
    if (isMintFree && !freeMintDone) {
      return false;
    }

    if (!count) {
      return true;
    }

    if (strkBalance < amountToMint) {
      return true;
    }

    if (Date.now() < LAUNCH_TIME) {
      return true;
    }

    return false;
  }, [isMintFree, count, strkBalance, amountToMint, freeMintDone]);

  const renderButton = useCallback(() => {
    if (!walletAddress) {
      return <ConnectButton />;
    }

    // if (hasMismatch && sdkChainId) {
    //   return (
    //     <Button.White
    //       onClick={() => switchChain(sdkChainId)}
    //       corner
    //     >
    //       SWITCH NETWORK
    //     </Button.White>
    //   );
    // }

    return (
      <Button.White
        // onClick={onMint}
        corner
        // disabled={isButtonDisabled}
        disabled
      >
        {/* {isMintFree ? 'MINT FOR FREE' : `MINT ${count}`} */}
        SOLD OUT
      </Button.White>
    );
  }, [walletAddress, onMint, isButtonDisabled]);

  return (
    <>
      <Container>
        <VideoBG
          loop
          autoPlay
          playsInline
          muted
          poster={poster}
        >
          <source
            src={'/videos/mint-bg.mp4'}
            type='video/mp4'
          />
        </VideoBG>

        <Frame>
          <Caption>
            <div style={{ marginLeft: isMobile ? 0 : 64 }}>
              MANY GALAXIES. ONE ARENA.
            </div>

            <div>ARE YOU READY?</div>
          </Caption>
          <BoxContainer>
            <Box>
              <AlternovaLogo src={logo} />
              <BoxSubtext>BECOME ALTER NATIVE NOW</BoxSubtext>
              {/* <BoxLabel>How many will you mint, voyager?</BoxLabel> */}

              <RemainingCountContainer>
                <RemainingCount>
                  {isMintFree
                    ? Number(maxSupply) - Number(totalSupply)
                    : Number(maxSupply) - Number(totalSupply) || '0'}
                </RemainingCount>

                <RemainingCountText>
                  of{' '}
                  {isMintFree
                    ? Number(maxSupply) - 200
                    : maxSupply?.toString() || '0'}{' '}
                  remain
                </RemainingCountText>
              </RemainingCountContainer>

              {/* <MintCountContainer>
                {COUNTS.map(({ value, label }) => (
                  <MintCount
                    active={count === value}
                    onClick={() => setCount(value)}
                  >
                    <MintCountNum>{value}</MintCountNum>
                    <MintCountWord>{label}</MintCountWord>
                  </MintCount>
                ))}
              </MintCountContainer> */}

              {!isMintFree ? (
                <>
                  <PokeballContainer>
                    {[...new Array(5)].map((_, index) => (
                      <Pokeball
                        onClick={() => onItemClick(index)}
                        src={
                          selectedIncubators.has(index)
                            ? incubatorColor
                            : incubatorWhite
                        }
                      />
                    ))}
                  </PokeballContainer>

                  <PokeballText>
                    Click to select your NFT incubator
                  </PokeballText>

                  {/* <TotalContainer>
                    <TotalText>TOTAL</TotalText>
                    <TotalText>
                      {getFormattedNumber(amountToMint, 2, 6)} STRK
                    </TotalText>
                  </TotalContainer> */}
                </>
              ) : null}

              {isMintFree && freeMintDone ? (
                <TotalContainer>
                  <TotalText>Claimed free Alter Nova!</TotalText>
                </TotalContainer>
              ) : null}

              {renderButton()}

              {walletAddress && !isMintFree ? (
                <EthBalance>
                  STRK Balance: {getFormattedNumber(strkBalance, 6, 6)}
                </EthBalance>
              ) : null}

              {!isMobile ? (
                <>
                  <CornerTopLeft src={corner} />
                  <CornerTopRight src={corner} />
                  <CornerBottomRight src={corner} />
                  <CornerBottomLeft src={corner} />
                </>
              ) : null}
              <BoxTopGraphic src={boxTop} />
              <BoxBottomGraphic src={boxBottom} />
            </Box>
          </BoxContainer>
        </Frame>
      </Container>
      <MintModal
        open={isModalOpen}
        onClose={closeModal}
        count={isMintFree ? 1 : count}
        loading={loading}
        success={success}
      />
    </>
  );
}

export default Mint;
