import React, { useCallback, useMemo } from 'react';
import { Button, Dropdown, Toast } from 'base-components';
import { getPrintableWalletAddress } from 'utils/wallet';
import {
  BtnContainer,
  Caption,
  Container,
  CopyBtn,
  CopyIcon,
  EthBalance,
  EthBalanceValue,
  Title,
  WalletAddress,
  WalletAddressContainer,
} from './WalletDropdown.styles';

import copyIcon from 'assets/copy.svg';
import { getFormattedNumber } from 'utils/format';
import { copyToClipboard } from 'utils/clipboard';
import { useAccount, useBalance, useDisconnect } from '@starknet-react/core';

const renderWalletAddress = (walletAddress) => {
  if (!walletAddress) {
    return '';
  }

  return `${walletAddress.substring(0, 16)}...${walletAddress.substring(
    walletAddress.length - 4
  )}`;
};

function WalletDropdown() {
  const { account, address: walletAddress, status } = useAccount();
  const { disconnect } = useDisconnect();
  const {
    isLoading: isLoadingEth,
    isError,
    error,
    data: dataEth,
  } = useBalance({
    address: walletAddress,
    watch: true,
  });

  const ethBalance = useMemo(() => {
    if (isLoadingEth || !dataEth) {
      return 0;
    }

    return Number(dataEth?.formatted);
  }, [dataEth, isLoadingEth]);

  const onCopy = useCallback(() => {
    copyToClipboard(walletAddress);

    Toast.success('Wallet address copied to clipboard!');
  }, [walletAddress]);

  return (
    <Dropdown buttonLabel={`USER ${getPrintableWalletAddress(walletAddress)}`}>
      <Container>
        <Title>MY WALLET</Title>

        <Caption>Wallet Address</Caption>
        <WalletAddressContainer>
          <WalletAddress>{renderWalletAddress(walletAddress)}</WalletAddress>

          <CopyBtn onClick={onCopy}>
            <CopyIcon src={copyIcon} />
          </CopyBtn>
        </WalletAddressContainer>

        <EthBalance>
          <div>ETH Balance</div>
          <EthBalanceValue>
            {getFormattedNumber(ethBalance, 2, 6)}
          </EthBalanceValue>
        </EthBalance>

        <BtnContainer>
          <Button onClick={disconnect}>DISCONNECT</Button>
        </BtnContainer>
      </Container>
    </Dropdown>
  );
}

export default WalletDropdown;
