import { REVEAL_TIME } from 'constants/time';
import { observable, makeObservable, action, computed } from 'mobx';
import { getNFTJsonUrl } from 'utils/nft';

class NftStore {
  userNFTs: any;

  constructor() {
    this.userNFTs = {};

    makeObservable(this, {
      userNFTs: observable,
      nftList: computed,
      setUserNFTs: action.bound,
    });

    this.loadNFTs = this.loadNFTs.bind(this);
  }

  async loadNFTs(ids) {
    const loadPromises = ids.map((id) => {
      const url = getNFTJsonUrl(id);

      return fetch(url).then((response) => {
        return response.json();
      });
    });

    const res = await Promise.all(loadPromises);

    const userNFTs = {};

    res.forEach((nftDetails, index) => {
      userNFTs[ids[index]] = nftDetails;
    });

    this.setUserNFTs(userNFTs);
  }

  setUserNFTs(userNFTs) {
    this.userNFTs = userNFTs;
  }

  get nftList() {
    return Object.values(this.userNFTs);
  }
}

const nftStore = new NftStore();

export default nftStore;
