import React from 'react';
import styled from '@emotion/styled';

const SpinnerSVG = styled.svg`
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  animation: rotator 1.4s linear infinite;
`;

const StyledCircle = styled.circle<{ light?: boolean }>`
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      // stroke-dashoffset: 187/4;
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }

  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
  stroke: ${({ light }) => (light ? '#fff' : '#000')};
`;

enum SIZES {
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL',
  XXL = 'XXL',
}

const SIZE_MAP = {
  [SIZES.S]: 24,
  [SIZES.M]: 27,
  [SIZES.L]: 30,
  [SIZES.XL]: 36,
  [SIZES.XXL]: 48,
};

interface SpinnerProps {
  size?: SIZES;
  light?: boolean;
}

export default function Spinner(props: SpinnerProps) {
  const { size = SIZES.M, light = false } = props;
  const dimension = SIZE_MAP[size];

  return (
    <SpinnerSVG
      width={dimension}
      height={dimension}
      viewBox='0 0 66 66'
      xmlns='http://www.w3.org/2000/svg'
    >
      <StyledCircle
        fill='none'
        strokeWidth='6'
        strokeLinecap='round'
        cx='33'
        cy='33'
        r='30'
        light={light}
      ></StyledCircle>
    </SpinnerSVG>
  );
}

Spinner.SIZES = SIZES;
