import { Button } from 'base-components';
import React, { useCallback, useMemo, useState } from 'react';
import { getPrintableWalletAddress } from 'utils/wallet';
import ConnectWalletModal from 'components/ConnectWalletModal';
import WalletDropdown from 'components/WalletDropdown';
import { useAccount, useDisconnect } from '@starknet-react/core';

function ConnectButton() {
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);

  const { disconnect } = useDisconnect();

  const { address: walletAddress } = useAccount();

  const btnText = useMemo(() => {
    if (!walletAddress) {
      return 'CONNECT WALLET';
    }

    return `USER ${getPrintableWalletAddress(walletAddress)}`;
  }, [walletAddress]);

  const handleClick = useCallback(() => {
    if (!walletAddress) {
      return setIsConnectModalOpen(true);
    }

    return disconnect();
  }, [walletAddress, disconnect]);

  return (
    <>
      {!walletAddress ? (
        <Button.White
          corner
          onClick={handleClick}
        >
          {btnText}
        </Button.White>
      ) : (
        <WalletDropdown />
      )}

      <ConnectWalletModal
        open={isConnectModalOpen}
        onClose={() => setIsConnectModalOpen(false)}
      />
    </>
  );
}

export default ConnectButton;
