import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Container,
  Content,
  EmptyBtnContainer,
  EmptyText,
  Header,
  NftListContainer,
  Title,
} from './Dashboard.styles';
import Frame from 'components/Frame';
import { Button, Toast } from 'base-components';
import ConnectButton from 'components/ConnectButton';
import { MINT_CONTRACT_ADDRESS } from 'constants/addresses';
import NftRenderer from 'components/NftRenderer';
import { NftStore } from 'stores';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import NftDetailsModal from 'components/NftDetailsModal/NftDetailsModal';
import { getIdFromName } from 'utils/nft';
import { useAccount, useContractRead } from '@starknet-react/core';
import { MINT_ABI } from 'abi/abi';

function Dashboard({ title }) {
  const { address: walletAddress } = useAccount();
  const walletConnected = Boolean(walletAddress);
  const navigate = useNavigate();

  const [selectedNft, setSelectedNft] = useState<string | null>(null);

  const { nftList, userNFTs } = NftStore;

  const nftDetails = useMemo(() => {
    if (!selectedNft) {
      return null;
    }

    return userNFTs[selectedNft];
  }, [selectedNft, userNFTs]);

  const onMint = useCallback(() => {
    navigate(ROUTES.MINT.path);
  }, [navigate]);

  const { data: tokenIds } = useContractRead({
    functionName: 'getTokenIds',
    args: [walletAddress!],
    abi: MINT_ABI,
    address: MINT_CONTRACT_ADDRESS,
    watch: true,
  });

  const tokenIdList = useMemo(
    // @ts-ignore
    () => tokenIds?.map((tokenId) => tokenId.toString()),
    [tokenIds]
  );

  useEffect(() => {
    if (!tokenIdList) {
      return;
    }

    NftStore.loadNFTs(tokenIdList);
  }, [tokenIdList]);

  const renderNotConnectedBody = useCallback(() => {
    return (
      <>
        <EmptyText>
          Your energy resonates across the nebula, even unseen... {'\n\n'}Who
          might you be, ethereal voyager?
        </EmptyText>

        <EmptyBtnContainer>
          <ConnectButton />
        </EmptyBtnContainer>
      </>
    );
  }, []);

  const renderEmptyBody = useCallback(() => {
    return (
      <>
        <EmptyText>
          Have you forgotten your quest to fight for the genesis? {'\n\n'}
          Choose your pathway now.
        </EmptyText>

        <EmptyBtnContainer>
          <Button.White
            onClick={onMint}
            corner
          >
            MINT NOW
          </Button.White>
        </EmptyBtnContainer>
      </>
    );
  }, [onMint]);

  const renderList = useCallback(() => {
    return (
      <NftListContainer>
        {nftList.map((nft: any) => (
          <NftRenderer
            onClick={() => setSelectedNft(getIdFromName(nft.name))}
            image={nft.image}
          />
        ))}
      </NftListContainer>
    );
  }, [nftList]);

  const renderBody = useCallback(() => {
    if (!walletConnected) {
      return renderNotConnectedBody();
    }

    if (isEmpty(tokenIds)) {
      return renderEmptyBody();
    }

    return renderList();
  }, [
    walletConnected,
    renderEmptyBody,
    renderNotConnectedBody,
    renderList,
    tokenIds,
  ]);

  return (
    <Container>
      <Frame>
        <Header>
          <Title>DASHBOARD</Title>
        </Header>

        <Content>{renderBody()}</Content>
      </Frame>

      <NftDetailsModal
        open={Boolean(selectedNft)}
        nftDetails={nftDetails}
        onClose={() => setSelectedNft(null)}
      />
    </Container>
  );
}

export default observer(Dashboard);
