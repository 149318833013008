import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 24px;
`;

export const Title = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 24px;
  font-weight: 900;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;

  margin-top: 32px;
`;

export const Caption = styled.div`
  background-color: #fff;
  border-radius: 2px;
  padding: 4px;
  font-family: Work Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;

  width: fit-content;

  margin-top: 32px;

  color: #000;
`;

export const WalletAddressContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  padding: 16px 12px;
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WalletAddress = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CopyBtn = styled.div`
  transition: 0.2s opacity;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.8;
  }
`;

export const CopyIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export const EthBalance = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px 0;
`;

export const EthBalanceValue = styled.div`
  font-weight: 500;
`;

export const BtnContainer = styled.div`
  margin-top: 48px;
  flex: 1;
  display: flex;
  align-items: flex-end;

  > div {
    width: 100%;
  }
`;
