import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

export const VideoBG = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 80%;
`;

export const MintBtnContainer = styled.div`
  z-index: 1000;

  position: absolute;
  top: 60vh;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  max-width: 260px;
  height: 80px;
  padding: 24px;

  /* par/regular */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */
  letter-spacing: -0.02em;

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;
