import React from 'react';
import { DropdownProps } from './Dropdown.types';
// import { MenuItem } from '@szhsin/react-menu';
import { Button } from 'base-components';
import { DropdownMenu, DropdownMenuButton } from './Dropdown.styles';

export default function Dropdown({
  // type,
  // size,
  // options,
  buttonLabel,
  children,
}: DropdownProps) {
  return (
    <DropdownMenu
      portal
      transition
      direction='bottom'
      align='end'
      gap={8}
      menuButton={
        <DropdownMenuButton>
          <Button.White
            corner
            // size={size}
          >
            {buttonLabel}
          </Button.White>
        </DropdownMenuButton>
      }
    >
      {children}
    </DropdownMenu>
  );
}
