export const getNFTImageUrl = (id: string) => {
  return `https://anovastark.s3.amazonaws.com/assets/${id}.png`;
};

export const getNFTJsonUrl = (id: string) => {
  return `https://anovastark.s3.amazonaws.com/assets/${id}.json`;
};

export const getIdFromName = (name: string) => {
  return name.split('#')[1];
};
