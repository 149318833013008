import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

import Modal from 'styled-react-modal';

export const StyledModal = Modal.styled<{ isMobile?: boolean }>`
  background-color: #475484;
  padding: 24px;
  color: #fff;
  border: 1px solid #fff;
  position: relative;
  box-sizing: border-box;

  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  min-height: 180px;
  min-width: 520px;

  ${({ isMobile }) =>
    isMobile
      ? `
    min-width: unset;
    max-width: 90vw;
  `
      : ''}
`;

export const CloseBtn = styled.div`
  cursor: pointer;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  user-select: none;

  position: absolute;
  top: -32px;
  right: 0;
`;

export const Corner = styled.img`
  height: 20px;
  width: 20px;
  position: absolute;
`;

export const CornerTopLeft = styled(Corner)`
  top: -48px;
  left: -36px;
`;

export const CornerTopRight = styled(Corner)`
  transform: rotate(90deg);
  top: -48px;
  right: -36px;
`;

export const CornerBottomRight = styled(Corner)`
  transform: rotate(180deg);
  bottom: -56px;
  right: -36px;
`;

export const CornerBottomLeft = styled(Corner)`
  transform: rotate(270deg);
  bottom: -56px;
  left: -36px;
`;

export const ModalTopGraphic = styled.img`
  height: 21px;
  position: absolute;

  top: -21px;
  left: -1px;
`;

export const ModalBottomGraphic = styled.img`
  height: 24px;
  position: absolute;

  background-color: #475484;
  border: 1px solid #fff;
  border-top: unset;

  clip-path: polygon(0% 0%, 99.6% 0%, 94.2% 100%, 0% 100%);

  bottom: -25px;
  left: -1px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 16px;
    bottom: -17px;
  }
`;
