export const ROUTES = {
  HOME: {
    key: 'home',
    label: 'Alter Nova',
    path: '/',
  },
  DASHBOARD: {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
  },
  TEAM: {
    key: 'team',
    label: 'Team',
    path: '/team',
  },
  MINT: {
    key: 'mint',
    label: 'Mint',
    path: '/mint',
  },
  NFT_DETAILS: {
    key: 'nft-details',
    label: 'NFT Details',
    path: '/details/:id',
  },
  NOT_FOUND: {
    key: 'not-found',
    label: 'Not Found',
    path: '/404', // also set up for '*'
  },
};

export const SIGNED_OUT_NAVIGATION_ROUTES = [
  // ROUTES.HOME,
  ROUTES.MINT,
  ROUTES.DASHBOARD,
];

export const NAVIGATION_ROUTES = [
  // ROUTES.HOME,
  ROUTES.MINT,
  ROUTES.DASHBOARD,
];
