import styled from '@emotion/styled';

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  z-index: 25;
  height: calc(100vh - 32px);
  width: calc(100vw - 32px);
  box-sizing: border-box;
  position: absolute;
  color: white;
  inset: 16px;
  font-family: 'Work Sans';

  /* border-radius: 0px 0px 8px 8px; */
  background: linear-gradient(
    180deg,
    rgba(19, 33, 58, 0.55) 0%,
    rgba(19, 33, 58, 0.13) 20%,
    rgba(19, 33, 58, 0) 100%
  );
`;

export const Sidebar = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.4);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Logo = styled.img`
  width: 64px;
  margin: 8px;
  cursor: pointer;
`;

export const Socials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
`;

export const SocialLink = styled.div`
  position: relative;

  :hover {
    img:nth-child(2),
    img:nth-child(3) {
      opacity: 1;
    }
  }
`;

export const Social = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.2s opacity;

  :hover {
    opacity: 1;
  }
`;

export const Main = styled.div`
  flex: 1;
`;

export const Header = styled.div`
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 16px;

  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const NavItem = styled.div`
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;

  :hover {
    img:nth-child(2),
    img:nth-child(3) {
      opacity: 1;
    }
  }
`;

export const NavItemIndicator = styled.div<{ active?: boolean }>`
  height: 4px;
  width: 4px;

  ${({ active }) => (active ? `background-color: #fff;` : '')}
`;

export const ConnectBtnContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;

  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const LinkIcon = styled.img`
  height: 8px;
  width: 8px;
`;

export const Content = styled.div``;

export const Corner = styled.img`
  height: 6px;
  width: 6px;
  position: absolute;
  opacity: 0;
  transition: 0.2s opacity;
`;

export const CornerTopRight = styled(Corner)<{ link?: boolean }>`
  transform: rotate(270deg);
  top: 0px;
  right: ${({ link }) => (link ? -12 : -6)}px;
`;

export const CornerBottomLeft = styled(Corner)`
  transform: rotate(90deg);
  bottom: 0px;
  left: -6px;
`;
