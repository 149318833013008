import React, { ReactNode } from 'react';

import { noop } from 'lodash';

import {
  CloseBtn,
  CornerBottomLeft,
  CornerBottomRight,
  CornerTopLeft,
  CornerTopRight,
  ModalBottomGraphic,
  ModalTopGraphic,
  StyledModal,
} from './Modal.styles';

import corner from 'assets/corner.svg';
import modalTop from 'assets/modal-top.svg';
import modalBottom from 'assets/modal-bottom.svg';
import { ResponsiveStore } from 'stores';

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  style?: any;
  children?: ReactNode;
}

function Modal({ open = false, onClose, children = null, style }: ModalProps) {
  const { isMobile } = ResponsiveStore;

  return (
    <StyledModal
      isOpen={open}
      onBackgroundClick={onClose ? onClose : undefined}
      onEscapeKeydown={onClose ? onClose : undefined}
      backgroundProps={{
        style: { backgroundColor: 'rgba(28, 27, 44, 0.97)', zIndex: 1300 },
      }}
      style={style}
      isMobile={isMobile}
    >
      {children}

      {onClose ? <CloseBtn onClick={onClose}>CLOSE</CloseBtn> : null}

      {!isMobile ? (
        <>
          <CornerTopLeft src={corner} />
          <CornerTopRight src={corner} />
          <CornerBottomRight src={corner} />
          <CornerBottomLeft src={corner} />
        </>
      ) : null}
      <ModalTopGraphic src={modalTop} />
      <ModalBottomGraphic src={modalBottom} />
    </StyledModal>
  );
}

export default Modal;
