import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

export const VideoBG = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const Caption = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 42px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;

  padding: 80px 80px 0;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 48px 80px 0;
    font-size: 36px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px;
    font-size: 22px;
  }
`;

export const BoxContainer = styled.div`
  padding: 80px;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 48px 80px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 48px 8px;
  }
`;

export const Box = styled.div`
  padding: 32px;
  min-width: 320px;
  background-color: #475484;
  border: 1px solid #fff;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: fit-content;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 24px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
    min-width: unset;
    font-size: 36px;
  }
`;

export const AlternovaLogo = styled.img`
  width: 240px;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    width: 180px;
  }
`;

export const BoxSubtext = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  margin-top: 8px;
`;

export const BoxLabel = styled.div`
  margin-top: 48px;
  margin-bottom: 32px;

  font-family: Work Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;

export const MintCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const MintCount = styled.div<{ active?: boolean }>`
  padding: 12px 24px;
  border-radius: 4px;
  gap: 8px;
  cursor: pointer;
  transition: 0.2s background-color;

  ${({ active }) =>
    active
      ? `
  background-color: #13213a;
  `
      : ''}

  :hover {
    background-color: #13213a;
    opacity: 0.9;
  }
`;

export const MintCountNum = styled.div`
  font-family: Work Sans;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0.05em;
  text-align: center;
`;

export const MintCountWord = styled.div`
  font-family: Work Sans;
  font-size: 8px;
  font-weight: 400;
  line-height: 9px;
  letter-spacing: 0.05em;
  text-align: center;

  margin-top: 8px;
`;

export const RemainingCountContainer = styled.div`
  margin: 16px auto;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    margin: 16px auto;
  }
`;

export const RemainingCount = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;

export const RemainingCountText = styled.div`
  font-family: Work Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: right;
`;

export const BoxTopGraphic = styled.img`
  height: 18px;
  position: absolute;

  top: -18px;
  left: -1px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 12px;
    top: -12px;
  }
`;

export const BoxBottomGraphic = styled.img`
  height: 24px;
  position: absolute;
  background-color: #475484;
  border: 1px solid #fff;
  border-top: unset;

  clip-path: polygon(0% 0%, 100% 0%, 87.1% 100%, 0% 100%);

  bottom: -25px;
  left: -1px;
`;

export const EthBalance = styled.div`
  font-family: Work Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
`;

export const PokeballContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* gap: 8px; */

  margin-bottom: 16px;
`;

export const Pokeball = styled.img`
  height: 32px;
  width: 32px;
  cursor: pointer;
  padding: 4px;
  transition: 0.2s;
  border-radius: 4px;

  :hover {
    background-color: #13213a;
    opacity: 0.9;
  }
`;

export const PokeballText = styled.div`
  font-family: Work Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;

  margin-bottom: 16px;
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  padding: 8px 0;

  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);

  margin-bottom: 16px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 100%;
  }
`;

export const TotalText = styled.div`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
`;
