import React, { useCallback } from 'react';
import { Button, Modal } from 'base-components';
import {
  AlternovaLogo,
  LoadingContainer,
  LoadingBall,
  LoadingText,
  Title,
  TitleText,
  SuccessContainer,
  MintCountText,
  Content,
  ContentText,
  NftContainer,
  BtnContainer,
} from './MintModal.styles';

import { times } from 'lodash';

import ballLoading from 'assets/ball_loading.gif';
import logo from 'assets/alternova.svg';
import NftRenderer from 'components/NftRenderer/NftRenderer';
import unrevealedNft from 'assets/unrevealed-nft.png';
import { ResponsiveStore } from 'stores';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

function MintModal({ open, onClose, loading, count, success }) {
  const { isMobile } = ResponsiveStore;
  const navigate = useNavigate();

  const onDashboard = useCallback(
    () => navigate(ROUTES.DASHBOARD.path),
    [navigate]
  );

  const renderLoading = useCallback(() => {
    return (
      <LoadingContainer>
        <LoadingBall src={ballLoading} />
        <LoadingText>
          Minting in progress, please approve in wallet.
        </LoadingText>
      </LoadingContainer>
    );
  }, []);

  const renderSuccess = useCallback(() => {
    // return `Successfully minted ${count} ALTERNOVA!`;

    return (
      <SuccessContainer>
        <Title>
          <AlternovaLogo src={logo} />
          <TitleText>MINTED</TitleText>
        </Title>
        {count > 1 ? <MintCountText>X{count} REVEALED</MintCountText> : null}

        {count === 1 ? (
          <Content>
            <NftRenderer image={unrevealedNft} />
            {/* <ContentText>
              You can reveal minted ALTER NOVA in 24 hours.
            </ContentText> */}
          </Content>
        ) : (
          <Content>
            <NftContainer>
              {times(count, () => (
                <NftRenderer
                  size={NftRenderer.SIZES.S}
                  image={unrevealedNft}
                />
              ))}
            </NftContainer>
            {/* <ContentText>
              You can reveal minted ALTER NOVA in 24 hours.
            </ContentText> */}
          </Content>
        )}

        <BtnContainer>
          <Button onClick={onDashboard}>VIEW DASHBOARD</Button>
          <Button onClick={onClose}>MINT AGAIN</Button>
          <Button
            corner={!isMobile}
            onClick={onClose}
          >
            CLOSE
          </Button>
        </BtnContainer>
      </SuccessContainer>
    );
  }, [count, onClose, isMobile, onDashboard]);

  const renderBody = useCallback(() => {
    if (loading) {
      return renderLoading();
    }

    if (success) {
      return renderSuccess();
    }

    return null;
  }, [loading, success, renderLoading, renderSuccess]);

  return (
    <Modal
      open={open}
      onClose={success ? onClose : undefined}
      style={{ maxWidth: 800 }}
    >
      {renderBody()}
    </Modal>
  );
}

export default MintModal;
