import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Bottom,
  BottomLeft,
  BottomRight,
  Container,
  Content,
  DetailsContainer,
  DetailsSection,
  Flexbox,
  Logo,
  LogoContainer,
  NFTImage,
  Rect,
  Top,
  TopLeft,
  TopRight,
  Trait,
  TraitContainer,
  TraitName,
  TraitValue,
} from './NFTDetails.styles';

import logo from 'assets/alternova-rotated.svg';
import Frame from 'components/Frame';
import { useParams } from 'react-router-dom';
import { NftStore, ResponsiveStore } from 'stores';

function NFTDetails({ title }) {
  const { id = '' } = useParams();
  const [loaded, setLoaded] = useState(false);

  const { userNFTs } = NftStore;

  useEffect(() => {
    const load = async () => {
      await NftStore.loadNFTs([id]);
      setLoaded(true);
    };

    load();
  }, [id]);

  const nftDetails = useMemo(() => {
    if (!loaded) {
      return null;
    }

    return userNFTs[id];
  }, [userNFTs, id, loaded]);

  const { isMobile } = ResponsiveStore;

  const renderTraits = useCallback(() => {
    if (!nftDetails) {
      return null;
    }

    return (
      <TraitContainer>
        {nftDetails.attributes.map(({ trait_type, value }) => (
          <Trait>
            <TraitName>{trait_type}</TraitName>
            <TraitValue>{value}</TraitValue>
          </Trait>
        ))}
      </TraitContainer>
    );
  }, [nftDetails]);

  return (
    <Container>
      <Frame>
        <Content>
          {!isMobile ? (
            <LogoContainer>
              <Logo src={logo} />
            </LogoContainer>
          ) : null}
          <Flexbox>
            <NFTImage src={nftDetails?.image} />
          </Flexbox>

          <DetailsContainer>
            <DetailsSection>
              <Top>
                <TopLeft>
                  <Flexbox>
                    <Rect />
                    ID #
                  </Flexbox>
                </TopLeft>
                <TopRight>#{id}</TopRight>
              </Top>
              <Bottom>
                <BottomLeft>
                  <Flexbox>
                    <Rect />
                    TRAITS
                  </Flexbox>
                </BottomLeft>
                <BottomRight>{renderTraits()}</BottomRight>
              </Bottom>
            </DetailsSection>
          </DetailsContainer>
        </Content>
      </Frame>
    </Container>
  );
}

export default NFTDetails;
