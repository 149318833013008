import styled from '@emotion/styled';
import { NFT_RENDERER_SIZES } from './NftRenderer.types';

const SIZE_MAP = {
  [NFT_RENDERER_SIZES.S]: 120,
  [NFT_RENDERER_SIZES.L]: 200,
};

export const Container = styled.div<{
  size: NFT_RENDERER_SIZES;
  onClick?: any;
}>`
  height: ${({ size }) => SIZE_MAP[size]}px;
  width: ${({ size }) => SIZE_MAP[size]}px;
  position: relative;

  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}
`;

export const Frame = styled.img<{ size: NFT_RENDERER_SIZES }>`
  height: ${({ size }) => SIZE_MAP[size]}px;
  width: ${({ size }) => SIZE_MAP[size]}px;
  position: absolute;
  inset: 0;
  z-index: 9;
`;

export const Image = styled.img<{ size: NFT_RENDERER_SIZES }>`
  height: ${({ size }) => SIZE_MAP[size]}px;
  width: ${({ size }) => SIZE_MAP[size]}px;
  position: absolute;
  inset: 0;
  z-index: 8;
  clip-path: polygon(-55% 0%, 0% 0%, 88% 0%, 170% 100%, 0% 100%, 15% 100%);
`;
