import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const Container = styled.div`
  padding: 32px 48px;
  box-sizing: border-box;

  max-width: 400px;
  margin-right: 200px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    max-width: 95vw;
    margin-right: 0;
  }
`;

export const Title = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 42px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 36px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 32px;
  }
`;

export const Text = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ListContainer = styled.div`
  position: absolute;
  right: -36px;
  bottom: 36px;

  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    position: relative;
    right: unset;
    bottom: unset;
    margin-top: 16px;
  }
`;

export const AcceptedWallets = styled.div`
  background-color: #fff;
  color: #000;
  padding: 4px;
  width: fit-content;

  border-radius: 2px;
  font-family: Work Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
`;

export const WalletIcon = styled.img`
  height: 28px;
  width: 28px;
  margin-right: 8px;
  margin-left: 8px;
`;
