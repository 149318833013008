import React, { ReactNode, useCallback, useState } from 'react';
import { Spinner } from 'base-components';

import {
  BaseButton,
  ButtonContainer,
  ButtonShadow,
  ChildrenWrapper,
  SpinnerWrapper,
  WhiteButton,
} from './Button.styles';

const noop = () => {};

enum THEMES {
  BLUE = 'BLUE',
  WHITE = 'WHITE',
}

interface ButtonProps {
  children?: ReactNode;
  theme?: THEMES;
  onClick?: () => void;
  disabled?: boolean;
  corner?: boolean;
  left?: boolean;
}

function Button({
  children,
  theme = THEMES.BLUE,
  onClick = noop,
  disabled,
  corner,
  left,
  ...restProps
}: ButtonProps) {
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    async (...args) => {
      if (disabled || loading) {
        return;
      }

      setLoading(true);

      let res;
      try {
        res = await onClick(...(args as []));
      } catch (e) {
      } finally {
        setLoading(false);
      }

      return res;
    },
    [onClick, disabled, loading]
  );

  const ButtonComponent = theme === THEMES.BLUE ? BaseButton : WhiteButton;

  return (
    <ButtonContainer>
      <ButtonComponent
        disabled={disabled || loading}
        onClick={handleClick}
        corner={corner}
        left={left}
        loading={loading}
        {...restProps}
      >
        <>
          {loading ? (
            <SpinnerWrapper>
              <Spinner
                light={theme === THEMES.BLUE}
                size={Spinner.SIZES.S}
              />
            </SpinnerWrapper>
          ) : null}

          <ChildrenWrapper loading={loading}>{children}</ChildrenWrapper>
        </>
      </ButtonComponent>

      <ButtonShadow
        disabled={disabled}
        corner={corner}
      />
    </ButtonContainer>
  );
}

Button.THEMES = THEMES;

Button.White = (props: ButtonProps) => (
  <Button
    theme={THEMES.WHITE}
    {...props}
  />
);

export default Button;
