import React, { ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Container,
  Main,
  Logo,
  Sidebar,
  Social,
  Socials,
  Content,
  Header,
  ConnectBtnContainer,
  NavItems,
  NavItem,
  LinkIcon,
  NavItemIndicator,
  CornerTopRight,
  CornerBottomLeft,
  SocialLink,
} from './Frame.styles';

import an from 'assets/an.svg';
import discord from 'assets/discord.svg';
import twitter from 'assets/twitter.svg';
import link from 'assets/link.svg';
import corner from 'assets/corner.svg';

import { NAVIGATION_ROUTES, ROUTES } from 'constants/routes';
import { DISCORD_URL, TWITTER_URL } from 'constants/socials';

import ConnectButton from 'components/ConnectButton';

interface FrameProps {
  children?: ReactNode;
}

function Frame({ children = null }: FrameProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const onHome = useCallback(() => {
    navigate(ROUTES.HOME.path);
  }, [navigate]);

  const onDiscord = useCallback(() => {
    window.open(DISCORD_URL, '_blank');
  }, []);
  const onTwitter = useCallback(() => {
    window.open(TWITTER_URL, '_blank');
  }, []);

  const onNavigate = useCallback(
    (path) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <Container>
      <Sidebar>
        <Logo
          src={an}
          onClick={onHome}
        />

        <Socials>
          <SocialLink onClick={onDiscord}>
            <Social src={discord} />
            <CornerTopRight src={corner} />
            <CornerBottomLeft src={corner} />
          </SocialLink>

          <SocialLink onClick={onTwitter}>
            <Social src={twitter} />
            <CornerTopRight src={corner} />
            <CornerBottomLeft src={corner} />
          </SocialLink>
        </Socials>
      </Sidebar>

      <Main>
        <Header>
          <NavItems>
            {NAVIGATION_ROUTES.map(({ label, path }) => (
              <NavItem onClick={() => onNavigate(path)}>
                <NavItemIndicator active={location.pathname === path} />
                {label}

                <CornerTopRight
                  link
                  src={corner}
                />
                <CornerBottomLeft src={corner} />
              </NavItem>
            ))}
          </NavItems>
          <ConnectBtnContainer>
            <ConnectButton />
          </ConnectBtnContainer>
        </Header>
        <Content>{children}</Content>
      </Main>
    </Container>
  );
}

export default Frame;
