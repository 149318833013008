import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { ROUTES } from 'constants/routes';

import Home from 'pages/Home';
import Mint from 'pages/Mint';
import Dashboard from 'pages/Dashboard';
import NFTDetails from 'pages/NFTDetails';
import NotFound from 'pages/NotFound';

import { Slide } from 'react-toastify';
import { StyledToastContainer } from 'base-components/Toast/Toast.styles';
import 'react-toastify/dist/ReactToastify.css';

import { ModalProvider } from 'styled-react-modal';
import StarknetProvider from 'components/StarknetProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: ROUTES.HOME.path,
    element: <Home title={ROUTES.HOME.label} />,
  },
  {
    path: ROUTES.DASHBOARD.path,
    element: <Dashboard title={ROUTES.DASHBOARD.label} />,
  },
  {
    path: ROUTES.MINT.path,
    element: <Mint title={ROUTES.MINT.label} />,
  },
  {
    path: ROUTES.NFT_DETAILS.path,
    element: <NFTDetails title={ROUTES.NFT_DETAILS.label} />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: ROUTES.NOT_FOUND.path,
    element: <NotFound />,
  },
]);

root.render(
  <React.StrictMode>
    <StarknetProvider>
      <ModalProvider>
        <RouterProvider router={router} />

        <StyledToastContainer
          position='bottom-right'
          hideProgressBar
          closeButton={false}
          limit={5}
          autoClose={7000}
          transition={Slide}
        />
      </ModalProvider>
    </StarknetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
