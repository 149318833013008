import { observable, makeObservable, action, computed } from 'mobx';
import { forEach } from 'lodash';
import {
  RESPONSIVE_MARKERS,
  getActiveLayout,
  MOBILE,
  TABLET,
  DESKTOP,
} from './ResponsiveStore.constants';

class ResponsiveStore {
  activeLayout: any;

  constructor() {
    this.activeLayout = getActiveLayout(window.innerWidth);

    makeObservable(this, {
      activeLayout: observable,
      updateActiveLayout: action.bound,
      isMobile: computed,
      isTablet: computed,
      isDesktop: computed,
    });

    this.attachResponsiveListeners();
  }

  attachResponsiveListeners() {
    if (!window.matchMedia) {
      return;
    }

    forEach(RESPONSIVE_MARKERS, (marker) => {
      const responsiveMediaQ = window.matchMedia(marker.media);

      // If the addEventListener API is supported then we use that to listen to status
      // change of the media query, this is false for Safari < v14
      if ('addEventListener' in responsiveMediaQ) {
        responsiveMediaQ.addEventListener('change', (media) => {
          media.matches && this.updateActiveLayout(marker.name);
        });
      }

      // Otherwise if the addListener API is supported then we use that
      // This will be true when we're testing in Safari < v14
      else if ('addListener' in responsiveMediaQ) {
        // @ts-ignore
        responsiveMediaQ.addListener((media) => {
          media.matches && this.updateActiveLayout(marker.name);
        });
      }
    });
  }

  updateActiveLayout(activeLayout) {
    this.activeLayout = activeLayout;
  }

  // Currently set to tablet also
  get isMobile() {
    return this.activeLayout === MOBILE || this.activeLayout === TABLET;
  }

  // Temporary - while we have isMobile set to tablet
  get isStrictlyMobile() {
    return this.activeLayout === MOBILE;
  }

  get isTablet() {
    return this.activeLayout === TABLET;
  }

  get isDesktop() {
    return this.activeLayout === DESKTOP;
  }
}

const responsiveStore = new ResponsiveStore();

export default responsiveStore;
