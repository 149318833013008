import React from 'react';
import { Container, Frame, Image } from './NftRenderer.styles';

import frame from 'assets/nft-frame.png';
import { NFT_RENDERER_SIZES } from './NftRenderer.types';

interface NftRendererProps {
  image?: any;
  size?: NFT_RENDERER_SIZES;
  onClick?: () => void;
}

function NftRenderer({
  image,
  size = NFT_RENDERER_SIZES.L,
  onClick,
}: NftRendererProps) {
  return (
    <Container
      onClick={onClick}
      size={size}
    >
      <Frame
        src={frame}
        size={size}
      />
      <Image
        src={image}
        size={size}
      />
    </Container>
  );
}

NftRenderer.SIZES = NFT_RENDERER_SIZES;

export default NftRenderer;
