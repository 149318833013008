import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: var(
    --gradient,
    radial-gradient(
      74.8% 76.59% at 29.59% 26.17%,
      #a3dcff 0%,
      #e8b1f8 39.06%,
      #475384 100%
    )
  );
`;

export const Header = styled.div`
  padding: 120px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 80px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 80px 8px;
  }
`;

export const Title = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 112px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    font-size: 96px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    font-size: 24px;
  }
`;

export const Content = styled.div`
  padding: 80px;
  overflow-y: auto;
  max-height: calc(100vh - 480px);
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 48px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
  }
`;

export const EmptyText = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  white-space: pre-line;

  margin-bottom: 120px;
`;

export const EmptyBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NftListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 64px;
  overflow-y: auto;
`;
