import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    min-width: 200px;
    width: fit-content;
    max-width: 400px;
  }
  .Toastify__toast {
    background-color: transparent;
    color: #000;
    box-shadow: none;
    border-radius: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    overflow: hidden;
    padding: 0;

    min-width: 200px;
    width: fit-content;
    max-width: 400px;
    margin-left: auto;

    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 15% 100%);
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;

export const ToastContentContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

export const ToastImageContainer = styled.div`
  /* background-color: var(--beige-20); */
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

export const ToastImage = styled.img`
  height: 120px;
  width: 120px;
`;

export const ToastContent = styled.div`
  position: relative;
  padding: 16px;
  padding-left: 48px;
  flex: 1;
  background-color: #fff;

  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
`;

export const ToastContentTitle = styled.div`
  font-size: 18px;
  line-height: 150%;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const ToastContentMsg = styled.div`
  margin-bottom: 4px;
`;

export const ToastContentLink = styled.a`
  /* color: var(--beige-100); */
  font-weight: 400;
  text-decoration: underline;
`;

export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const ToastLine = styled.div<{ color?: string }>`
  width: 12px;
  margin-right: 4px;
  margin-left: 4px;

  background-color: ${({ color }) => color};
`;

export const ToastLineRight = styled.div`
  background-color: #fff;
  width: 12px;
`;
