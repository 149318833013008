export enum TOAST_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export interface ToastOptions {
  txId?: string;
  title?: string;
}

export const DEFAULT_TITLES = {
  [TOAST_TYPES.SUCCESS]: 'Success',
  [TOAST_TYPES.ERROR]: 'Error',
  [TOAST_TYPES.INFO]: 'Info',
};

export interface ToastBodyProps {
  message: string;
  type: TOAST_TYPES;
  title?: string;
  txId?: string;
}
