import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const LoadingContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
  justify-content: center;
`;

export const LoadingBall = styled.img`
  height: 180px;
  width: 180px;
`;

export const LoadingText = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

export const SuccessContainer = styled.div`
  box-sizing: border-box;
  padding: 24px 36px;

  margin-right: 48px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 0;
    margin-right: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
  }
`;

export const AlternovaLogo = styled.img`
  height: 42px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 32px;
  }
`;

export const TitleText = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
`;

export const MintCountText = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  color: rgba(255, 255, 255, 0.8);
`;

export const Content = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  gap: 48px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`;

export const NftContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex: 1;
`;

export const ContentText = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  flex: 0;
  flex-basis: 260px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-basis: 0;
  }
`;

export const BtnContainer = styled.div`
  position: absolute;
  right: -36px;
  bottom: 36px;

  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    position: relative;
    right: unset;
    bottom: unset;
  }
`;
