import styled from '@emotion/styled';

export const ButtonContainer = styled.div`
  height: 52px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 200px;
  max-width: 300px;

  :hover {
    div:nth-child(2) {
      opacity: 1;
    }
  }
`;

export const BaseButton = styled.button<{
  corner?: boolean;
  left?: boolean;
  loading?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: 48px;
  z-index: 10;
  box-sizing: border-box;

  background-color: #475484;
  border-radius: 3px;
  border: 1px solid #fff;

  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s top, 0.2s left;

  :active {
    top: 4px;
    left: 4px;
  }

  ${({ corner }) =>
    corner
      ? `
    clip-path: polygon(0% 0%, 100% 0%, 120% 10%, 90% 100%, 0% 100%);
    background: linear-gradient(144deg, #475484 92.6%, #fff 0%);
  `
      : ''}

  ${({ left }) =>
    left
      ? `
    justify-content: flex-start;
  `
      : ''}

  ${({ loading }) =>
    loading
      ? `
    justify-content: center !important;
  `
      : ''}



  ${({ disabled }) =>
    disabled
      ? `
    background-color: rgb(71, 84, 132, 0.6) !important;
    color: #ddd;
    cursor: not-allowed;
    box-shadow: none !important;

    :active {
      top: 0px;
      left: 0px;
    }
  `
      : ''}
`;

export const ButtonShadow = styled.div<{
  corner?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 4px);
  height: 48px;
  z-index: 9;
  box-sizing: border-box;

  border-radius: 3px;
  border: 1px solid #fff;
  background-color: transparent;
  transition: 0.2s opacity;

  opacity: 0;

  ${({ corner }) =>
    corner
      ? `
  
  clip-path: polygon(0% 0%, 100% 0%, 120% 10%, 90% 100%, 0% 100%);
  background: linear-gradient(144deg, transparent 92.6%, #fff 0%);
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    opacity: 0 !important;
  `
      : ''}
`;

export const WhiteButton = styled(BaseButton)`
  background-color: #fff;
  color: #000;

  ${({ corner }) =>
    corner
      ? `
    clip-path: polygon(0% 0%, 100% 0%, 120% 10%, 90% 100%, 0% 100%);
    background: linear-gradient(144deg, #fff 92.6%, #fff 0%);
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    background: #ddd !important;
    color: #777;
  `
      : ''}
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  margin: auto;
  display: flex;
`;

export const ChildrenWrapper = styled.div<{ loading: boolean }>`
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  display: flex;
  align-items: center;
`;
