import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const Container = styled.div`
  background-color: #1c1b2b;
  position: relative;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  /* align-items: stretch; */
  /* justify-content: space-between; */
  /* gap: 32px; */
  height: calc(100vh - 112px);
  /* width: calc(100vw - 32px); */
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
  }
`;

export const Flexbox = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled(Flexbox)`
  flex-basis: 160px;
  width: 160px;
  justify-content: center;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    width: 50vw;
    flex-basis: 50vw;
  }
`;

export const Logo = styled.img`
  display: block;
  height: 70vh;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    height: 60vh;
  }
`;

export const NFTImage = styled.img`
  display: block;
  height: 70vh;
  width: 70vh;
  border-radius: 8px;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    height: 60vh;
    width: 60vh;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: unset;
    width: 50vw;
    margin: auto;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 32px;
  margin-right: 32px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export const DetailsSection = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  height: 70vh;
  display: flex;
  flex-direction: column;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    height: 60vh;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: unset;
  }
`;

export const Top = styled.div`
  display: flex;
`;

export const TopLeft = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  width: 120px;
  flex: 0 0 110px;

  padding: 24px;

  display: flex;
  align-items: center;

  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    flex: 0 0 72px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px;
  }
`;

export const TopRight = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  flex: 1;

  display: flex;
  align-items: center;

  padding: 12px 36px;

  font-family: 'Aviano Future', 'aviano-future';
  font-size: 52px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    font-size: 42px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px;
    font-size: 32px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex: 1;
`;

export const BottomLeft = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  width: 120px;
  flex: 0 0 110px;

  padding: 24px;

  display: flex;
  align-items: flex-start;

  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    flex: 0 0 72px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px;
  }
`;

export const BottomRight = styled.div`
  padding: 36px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 8px;
  }
`;

export const Rect = styled.div`
  height: 5px;
  width: 5px;
  background-color: #fff;
  margin-right: 8px;
`;

export const TraitContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    gap: 24px;
  }

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    grid-template-columns: 1fr;
  }
`;

export const Trait = styled.div`
  text-align: left;
`;

export const TraitName = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: 0em;
  text-align: left;

  color: #898798;

  margin-bottom: 4px;
  text-transform: uppercase;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    font-size: 13px;
  }
`;

export const TraitValue = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: 0em;
  text-align: left;

  color: #fff;

  text-transform: uppercase;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    font-size: 13px;
  }
`;
