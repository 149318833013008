import { MenuButton, Menu } from '@szhsin/react-menu';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import bg from 'assets/dropdown_bg.svg';

import {
  menuSelector,
  menuItemSelector,
  menuDividerSelector,
} from '@szhsin/react-menu/style-utils';

const menuShow = keyframes`
  from {
    opacity: 0;
  }
`;
const menuHide = keyframes`
  to {
    opacity: 0;
  }
`;

export const DropdownMenu = styled(Menu)`
  ${menuSelector.name} {
    z-index: 1000;
    box-sizing: border-box;
    list-style: none;
    user-select: none;

    padding: 12px;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;

    /* border: 2px solid var(--grey-100); */
    /* box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.4); */
    /* border-radius: 16px; */
    /* background-color: var(--beige-20); */
    min-width: 240px;
    background-color: #475484;
    background: url(${bg});
    background-size: contain;
    height: 380px;
    width: 350px;

    color: #fff;
  }

  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
    outline: none;
  }

  ${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    cursor: pointer;
    border-radius: 8px;
    padding: 16px;
  }

  ${menuItemSelector.hover} {
    /* background-color: var(--beige-40); */
  }

  ${menuItemSelector.disabled} {
    cursor: default;
    color: #aaa;
  }

  ${menuItemSelector.submenu} {
    position: relative;
    &::after {
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }

  ${menuDividerSelector.name} {
    height: 1px;
    margin: 0.5rem 0.625rem;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export const DropdownMenuButton = styled(MenuButton)`
  border: none;
  background: transparent;
`;
