import React, { useCallback } from 'react';
import { Button, Modal, Toast } from 'base-components';
import {
  AcceptedWallets,
  Container,
  ListContainer,
  Text,
  Title,
  WalletIcon,
} from './ConnectWalletModal.styles';

import { useConnect } from '@starknet-react/core';
import { ResponsiveStore } from 'stores';

function ConnectWalletModal({ open, onClose }) {
  const { connectAsync: connect, connectors } = useConnect();

  const handleClick = useCallback(
    async (connector) => {
      try {
        await connect({ connector });
        Toast.info('Wallet is now connected!');
        onClose();
      } catch (err) {
        Toast.error('Wallet connection failed');
      }
    },
    [connect, onClose]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        minHeight: 400,
      }}
    >
      <Container>
        <Title>Connect Wallet</Title>
        <Text>
          Link your wallet to unlock the cosmic gateway and manage your minted
          assets with ease.
        </Text>

        <ListContainer>
          <AcceptedWallets>Accepted Wallets</AcceptedWallets>

          {connectors.map((connector, index) => (
            <Button
              left
              corner={index === connectors.length - 1}
              onClick={() => handleClick(connector)}
            >
              <WalletIcon src={connector.icon.dark} />
              {connector.name}
            </Button>
          ))}
        </ListContainer>
      </Container>
    </Modal>
  );
}

export default ConnectWalletModal;
