import React from 'react';
import {
  Container,
  Logo,
  LogoContainer,
  MintBtnContainer,
  Text,
  VideoBG,
} from './Home.styles';

import logo from 'assets/alternova.svg';
import Frame from 'components/Frame';

import poster from 'assets/still_lander.webp';
import { Button } from 'base-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

function Home({ title }) {
  const navigate = useNavigate();

  const onMintClick = () => navigate(ROUTES.MINT.path);
  return (
    <Container>
      <VideoBG
        loop
        autoPlay
        playsInline
        muted
        poster={poster}
      >
        <source
          src={'/videos/landed.mp4'}
          type='video/mp4'
        />
      </VideoBG>

      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>

      <MintBtnContainer>
        <Button
          onClick={onMintClick}
          theme={Button.THEMES.WHITE}
        >
          Mint Now
        </Button>
      </MintBtnContainer>

      <Frame>
        <Text>
          Alter Nova is a quadrennial sporting event where Citizens from across
          the Multiverse come together to compete in teams, pushing the
          boundaries of competition, sports and the individual.
        </Text>
      </Frame>
    </Container>
  );
}

export default Home;
