import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: #1c1b2b;
`;

export const Header = styled.div`
  padding: 120px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;

  /* @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 64px;
  } */

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 112px;
  font-weight: 700;
  line-height: 112px;
  letter-spacing: 0em;
  text-align: center;

  /* @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    font-size: 64px;
  } */

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    font-size: 36px;
  }
`;

export const Content = styled.div`
  padding: 80px;
  overflow-y: auto;
  max-height: calc(100vh - 480px);
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP_L}) {
    padding: 24px;
  }

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    max-height: unset;
  }
`;

export const EmptyTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${DISPLAY_SIZES.LAPTOP}) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const EmptyText = styled.div`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  white-space: pre-line;

  margin-bottom: 120px;
`;

export const EmptyBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
