import styled from '@emotion/styled';
import { DISPLAY_SIZES } from 'constants/styles';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 24px 36px;

  margin-right: 48px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    margin-right: 0;
    padding: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
  }
`;

export const AlternovaLogo = styled.img`
  height: 42px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 32px;
  }
`;

export const TitleText = styled.div`
  font-family: 'Aviano Future', 'aviano-future';
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Content = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  gap: 48px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    justify-content: center;
    gap: 0;
  }
`;

export const BtnContainer = styled.div`
  position: absolute;
  right: -36px;
  bottom: 36px;

  display: flex;
  gap: 12px;
  flex-direction: column;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    position: relative;
    right: unset;
    bottom: unset;
  }
`;
