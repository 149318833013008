import { isString } from 'lodash';

export const getPrintableWalletAddress = (walletAddress) => {
  if (!walletAddress || !isString(walletAddress)) {
    return '';
  }

  return `${walletAddress.substring(0, 4)}...${walletAddress.substring(
    walletAddress.length - 4
  )}`;
};
