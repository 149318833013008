import React, { useCallback } from 'react';
import {
  Container,
  Content,
  EmptyBtnContainer,
  EmptyText,
  EmptyTextContainer,
  Header,
  Title,
} from './NotFound.styles';
import Frame from 'components/Frame/Frame';
import { Button } from 'base-components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

function NotFound() {
  const navigate = useNavigate();

  const onHome = useCallback(() => {
    navigate(ROUTES.HOME.path);
  }, [navigate]);

  return (
    <Container>
      <Frame>
        <Header>
          <Title>ERROR 404</Title>
        </Header>

        <Content>
          <EmptyTextContainer>
            <EmptyText>
              Even in the vastness of space, not all stars align. {'\n\n'}
              Reignite your thrusters and soar again, brave voyager.
            </EmptyText>
          </EmptyTextContainer>

          <EmptyBtnContainer>
            <Button.White
              onClick={onHome}
              corner
            >
              BACK TO HOME
            </Button.White>
          </EmptyBtnContainer>
        </Content>
      </Frame>
    </Container>
  );
}

export default NotFound;
