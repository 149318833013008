import React, { useCallback } from 'react';
import { Button, Modal } from 'base-components';
import {
  AlternovaLogo,
  BtnContainer,
  Container,
  Content,
  Title,
  TitleText,
} from './NftDetailsModal.styles';

import logo from 'assets/alternova.svg';
import NftRenderer from 'components/NftRenderer';
import { getIdFromName } from 'utils/nft';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { ResponsiveStore } from 'stores';

function NftDetailsModal({ open, onClose, nftDetails }) {
  const navigate = useNavigate();
  const { isMobile } = ResponsiveStore;

  const onMint = useCallback(() => {
    navigate(ROUTES.MINT.path);
  }, [navigate]);

  const onDetails = useCallback(() => {
    navigate(`/details/${getIdFromName(nftDetails?.name)}`);
  }, [navigate, nftDetails]);

  if (!nftDetails) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ maxWidth: isMobile ? 'unset' : 800 }}
    >
      <Container>
        <Title>
          <AlternovaLogo src={logo} />
          <TitleText>#{getIdFromName(nftDetails?.name)}</TitleText>
        </Title>

        <Content>
          <NftRenderer image={nftDetails?.image} />
        </Content>

        <BtnContainer>
          <Button onClick={onDetails}>VIEW DETAILS</Button>
          <Button onClick={onMint}>MINT AGAIN</Button>
          <Button
            corner={!isMobile}
            onClick={onClose}
          >
            CLOSE
          </Button>
        </BtnContainer>
      </Container>
    </Modal>
  );
}

export default NftDetailsModal;
